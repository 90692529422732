$('.summernote').summernote({
		tableClassName: function()
    {
        $(this).addClass('table table-bordered')
        .css('borderCollapse', 'collapse')
		.css('width', '100%');

        $(this).find('td')
        .css('border', '1px solid #888')
		.css('padding', '1%');
    },
	onCreateLink : function(originalLink) {
        return originalLink; // return original link 
    },
  height: 300,/*set editor height*/
  minHeight: null,/*set minimum height of editor*/
  maxHeight: null,/*set maximum height of editor*/
  codemirror: {/*codemirror options*/
    theme: 'monokai'
  },
  focus: false ,               /* set focus to editable area after initializing summernote*/
  toolbar: [
    /* [groupName, [list of button]]*/
    ['style', ['bold', 'italic', 'underline', 'clear']],
    ['fontsize', ['fontsize']],
	['fontname', ['fontname']],
    ['color', ['color']],
    ['para', ['ul', 'ol', 'paragraph']],
	['insert', ['link'/*, 'codeview'*/, 'hr']],
	['table', ['table']],
	//['insert', ['picture', 'link', 'emoji']]
  ],
  /*colors: [
       ["#000",color1, color2] //second line of colors
    ],*/
	 fontSizes: ['12', '14', '15', '16', '18', '20', '24']
 // fontNames: ['openSans']
  
});