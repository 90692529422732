;
(function($, document, window, undefined) {
    'use strict'; //this is just saying how to validate your JS.  If you use a tool like JSHint you can see your mistakes - test your code quality.
    // declare any variables you want to keep in the scope - keep refering to and updating in your functions.


    // DOCUMENT READY
    $(function() {
		
		var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
        if (window.location.hash && isChrome) {
            setTimeout(function () {
                var hash = window.location.hash;
                window.location.hash = "";
                window.location.hash = hash;
            }, 300);
        }
		
		$('button').prop('disabled' , false )
		
		bindEvents();
    });

    // EVENTS WHICH RELY ON THE DOM

    function bindEvents() {
		
$("#returntop button").click(function(){
$('html, body').animate({ scrollTop: 0 }, 'slow');
	})
	
	
$("input, select").focus(function(){
  $(this).removeClass('inputerror');
});
	
		
		}
})(jQuery, document, window);
